<template>
  <GameStage
    :stage="stage"
    :feedback="feedback"
    :badge="badge"
    :questions-count="questionsCount"
    :current-question-number="currentQuestionNumber"
    :is-loading="isLoading"
    :has-selected-answer="!!recordedData"
    :has-session-data="hasSessionRecord"
    :recorded-data="recordedData"
    :skip-enabled="!stage.required"
    @completed-show-badges="completedShowBadges"
    @completed="completed"
  >
    <div class="game-record">
      <div class="instruction">
        <h3
          v-if="stage.instruction"
        >
          {{ stage.instruction }}
        </h3>
      </div>
      <div class="answers">
        <Recorder
          v-if="!hasSessionRecord"
          :progress="getProgress"
          :time-limit="stage.timeLimit"
          @finish-record="saveRecordedData"
          @remove-recorded-data="removeRecordedData"
        />
      </div>
    </div>
  </GameStage>
</template>
<script>
import { mapGetters } from 'vuex';
import GameStage from '@/components/Stage/GameStage';
import Recorder from '@/components/Video/Recorder';
import GameRecord from '@/domain/entities/flow/GameRecord';

export default {
  name: 'GameRecord',
  components: {
    GameStage,
    Recorder,
  },
  props: {
    stage: {
      type: [GameRecord],
      required: true,
    },
    questionsCount: {
      type: Number,
      required: true,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    recordedData: null,
  }),
  computed: {
    ...mapGetters('layout', ['getProgress']),
    feedback() {
      return this.stage.feedback;
    },
    badge() {
      return this.stage.badge;
    },
    hasSessionRecord() {
      return this.stage.hasSessionRecord();
    },
  },
  methods: {
    saveRecordedData(data) {
      this.recordedData = data;
    },
    removeRecordedData() {
      this.recordedData = null;
    },
    completedShowBadges() {
      this.$emit('completed-show-badges');
    },
    completed() {
      this.$emit('completed');
    },
  },
};
</script>
<style lang="scss" scoped>
.game-record {
  h3 {
    text-transform: uppercase;
    color: $black;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3.4rem;
    @include media-min($lg) {
      text-transform: none;
    }
  }
}
</style>
